<div class="multi" [style.background-color]="getBackgroundColor(appt)">ID: {{appt._id}}
  <ng-container *ngIf="mode === 'cs' && appt.status === 'cancelled'">
    <button class="ml-2 small-button"
            mat-flat-button
            (click)="onRemoveCancelledAppointment.emit(appt); $event.stopPropagation()">
      {{ 'Action.Remove' | translate }}
    </button>
  </ng-container>
</div>

<!-- Compact Main Content - This wrapper helps with mobile positioning -->
<div class="compact-container">
  <!-- Left Side: Main Info -->
  <div class="info-section">
    <!-- Title, Date and Details -->
    <div class="appointment-details">
      <div class="title-row">
        <!-- Rejection indicator inline with title -->
        <ng-container *ngIf="allRejected()">
          <mat-icon class="big-icon">do_not_disturb_on</mat-icon>
        </ng-container>

        <b>{{ text }}</b>
        <ng-container *ngIf="mode==='cs'">
          @switch(appt.visibility) {
          @case("1") { <span>({{"team.allFav" | translate}})</span> }
          @case("2") { <span>({{"team.allSub" | translate}})</span> }
          @case("3") { <span>({{"team.both" | translate}})</span> }
          @case("4") { <div class="inline cg-names">(<div class="inline"
                 [innerHTML]="getCGNames(appt)"></div>)</div> }
          }
        </ng-container>
      </div>

      <div class="date-row dropdown-appointment-dates">
        {{
        (appt.startDate | date: sameDay(appt.startDate, appt.endDate) ? 'shortTime' : 'EEEE, h:mm a') +
        ' - ' +
        (appt.endDate | date: sameDay(appt.startDate, appt.endDate) ? 'shortTime' : 'EEEE, h:mm a')
        }}
      </div>

      <ng-container *ngIf="appt.remainingSubsidizedHours !== null && appt.remainingSubsidizedHours !== undefined">
        <div class="subsidized-row">
          {{"appointment.subsidized" | translate}} : {{appt.remainingSubsidizedHours}}
        </div>
      </ng-container>
    </div>

    <!-- People Info -->
    <div class="people-info">
      <!-- Only show caregiver name when NOT in visibility mode 4 (since it's already shown in the title area) -->
      <div *ngIf="mode==='cs' && appt.caregiverName && appt.visibility !== '4'"
           class="cg-name">
        <b>{{ getStrippedNameStr(appt.caregiverName) }}</b>
      </div>
      <div *ngIf="mode!=='cs' && isMine || !isMine && isHis(appt)"
           class="lo-list">
        <div *ngFor="let lo of appt.lovedOnesPassports">
          <span class="lo-name">patient: {{ getStrippedName(lo.LovedOnePersonalInfo.Name) }}</span>
        </div>
      </div>
    </div>
  </div>

  <!-- Right Side: Status/Time -->
  <div class="status-section">
    <!-- Time pills -->
    <div class="time-pills">
      <div class="pill in"
           *ngIf="appt.checkInOut && appt.checkInOut.checkIn">{{appt.checkInOut.checkIn.time | date : 'shortTime'}}
      </div>
      <div class="pill out"
           *ngIf="appt.checkInOut && appt.checkInOut.checkOut">{{appt.checkInOut.checkOut.time | date : 'shortTime'}}
      </div>
    </div>

    <!-- Error icon -->
    <div class="error-container"
         *ngIf="shouldBeIn() || shouldBeOut() || appt.checkInOut?.checkIn?.farDistanceReasonProvided || appt.checkInOut?.checkIn?.earlyOrLate || appt.checkInOut?.checkOut?.farDistanceReasonProvided || appt.checkInOut?.checkOut?.earlyOrLate">
      <mat-icon class="error-icon"
                (click)="showErrorPopup = !showErrorPopup; $event.stopPropagation()">error</mat-icon>
      <div class="error-popup"
           *ngIf="showErrorPopup"
           (click)="$event.stopPropagation()">
        <div class="pill error"
             *ngIf="shouldBeIn()">LATE! NOT CHECKED IN</div>
        <div class="pill error"
             *ngIf="shouldBeOut()">LATE! NOT CHECKED OUT</div>
        <div class="pill error-location"
             *ngIf="appt.checkInOut?.checkIn?.farDistanceReasonProvided">
          FAR CHECK-IN: <span>{{ appt.checkInOut?.checkIn?.farDistanceReason }}</span>
        </div>
        <div class="pill error"
             *ngIf="appt.checkInOut?.checkIn?.earlyOrLate">
          EARLY/LATE CHECK-IN: <span>{{ appt.checkInOut?.checkIn?.earlyOrLateReason }}</span>
        </div>
        <div class="pill error-location"
             *ngIf="appt.checkInOut?.checkOut?.farDistanceReasonProvided">
          FAR CHECK-OUT: <span>{{ appt.checkInOut?.checkOut?.farDistanceReason }}</span>
        </div>
        <div class="pill error"
             *ngIf="appt.checkInOut?.checkOut?.earlyOrLate">
          EARLY/LATE CHECK-OUT: Reason: {{ appt.checkInOut?.checkOut?.earlyOrLateReason }}
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Communication Notes Section -->
<div class="notes-section p-3"
     *ngIf="mode==='cs' && careSeeker.partnerId && careSeeker.partnerId !== null">
  <c4g-communication-notes [appt]="appt"
                           (click)="stopPropagation($event)"
                           (addedExpansionPanel)="addedExpansionPanelHandler($event)"
                           (noteAdded)="noteAdded.emit($event)">
  </c4g-communication-notes>
</div>
