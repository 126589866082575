<div class="notes-container">
  <mat-accordion class="notes-accordion">
    <mat-expansion-panel #matExpansion
                        [expanded]="appt.communications && appt.communications.length > 0"
                        class="notes-expansion-panel">
      <mat-expansion-panel-header class="notes-panel-header">
        <div class="notes-header-content">
          <div class="notes-title">
            <mat-icon class="notes-icon">note</mat-icon>
            <span>Notes {{appt.communications ? '(' + appt.communications.length +')' : '(0)'}}</span>
          </div>
          <button mat-mini-fab
                  color="primary"
                  class="add-note-btn"
                  [disabled]="disabled"
                  (click)="addNote(); stopPropagation($event)">
            <mat-icon>add</mat-icon>
          </button>
        </div>
      </mat-expansion-panel-header>

      <!-- Notes Content -->
      <div class="notes-content" *ngIf="appt.communications && appt.communications.length > 0; else noNotes">
        <div class="note-item" *ngFor="let note of appt.communications">
          <div class="note-header">
            <span class="note-author">{{ note.name }}</span>
            <span class="note-date">{{ note.date | date : "short"}}</span>
          </div>
          <div class="note-body" [innerHTML]="note.message"></div>
        </div>
      </div>

      <ng-template #noNotes>
        <div class="no-notes">
          <span>No notes added yet.</span>
        </div>
      </ng-template>
    </mat-expansion-panel>
  </mat-accordion>
</div>
